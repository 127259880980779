import * as THREE from 'three'
import Experience from './Experience';

export default class RayCaster 
{
    constructor()
    {
        this.experience = new Experience();
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera
        
       
        this.mouse = new THREE.Vector2();

        window.addEventListener('mousemove', (_event) => 
        {
            this.handlePointerMove(_event.clientX, _event.clientY);

        })
        window.addEventListener('touchstart', (event) => {
            if (event.touches.length === 1) {
                const touch = event.touches[0];
                this.handlePointerMove(touch.clientX, touch.clientY);
            }
        });
        this.setInstance();
    }
    
    handlePointerMove(clientX, clientY) 
    {
        this.mouse.x = (clientX / this.sizes.width) * 2 - 1;
        this.mouse.y = -(clientY / this.sizes.height) * 2 + 1;
    }

    setInstance() 
    {
        this.instance = new THREE.Raycaster();
        this.instance.setFromCamera(this.mouse, this.camera.instance);
    }

    update()
    {
        this.instance.setFromCamera(this.mouse, this.camera.instance);
    }
}