uniform sampler2D uPerlinTexture;
uniform float uTime;
precision mediump float;

varying vec2 vUv;


void main()
{

    vec2 smokeUv = vUv;
    smokeUv.x *= 0.5;
    smokeUv.y *= 0.3;
    smokeUv.y -= uTime * 0.07;

    float smoke = texture(uPerlinTexture, smokeUv).r;

    smoke = smoothstep(0.4, 0.9, smoke);
    
    smoke *= smoothstep(0.0, 0.1, vUv.x);
    smoke *= smoothstep(1.0, 0.9, vUv.x);
    smoke *= smoothstep(1.0, 0.5, vUv.y);
    smoke *= smoothstep(0.0, 0.1, vUv.y);
    smoke *= 0.7;
    gl_FragColor = vec4(1.0, 0.4, 0.3, smoke);
    //gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}