import * as THREE from "three";
import Experience from "../Experience.js";
import coffeSmokeFragmentShader from "../../shaders/coffeeSmoke/fragment.glsl";
import coffeSmokeVertexShader from "../../shaders/coffeeSmoke/vertex.glsl";
import { isMobile } from "../Utils/Mobile.js";

export default class CoffeeSmoke {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.group = this.experience.group;
    this.sizes = this.experience.sizes;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    this.time = this.experience.time;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("Coffee");
    }

    this.setModel();
  }

  setModel() {
    this.perlinTexture = this.resources.items.perlinTexture;
    this.perlinTexture.wrapS = THREE.RepeatWrapping;
    this.perlinTexture.wrapT = THREE.RepeatWrapping;
    this.smokeGeometry = new THREE.PlaneGeometry(1, 1, 16, 64);
    //this.smokeGeometry.translate(-2.2, -2.3, -0.4);
    this.smokeGeometry.scale(0.3, 1, 50.3);
        //this.smokeMaterial = new THREE.MeshBasicMaterial();
    this.smokeMaterial = new THREE.ShaderMaterial({
      vertexShader: coffeSmokeVertexShader,
      fragmentShader: coffeSmokeFragmentShader,
      side: THREE.DoubleSide,
      uniforms: {
        uTime: new THREE.Uniform(0),
        uPerlinTexture: new THREE.Uniform(this.perlinTexture),
      },
      transparent: true,
      depthWrite: false,
    });

    this.smoke = new THREE.Mesh(this.smokeGeometry, this.smokeMaterial);
    this.smoke.frustumCulled = false;
    this.smoke.position.x = -2.05;
    this.smoke.position.y = -4.2;
    this.smoke.position.z = 0.905;
    //this.smoke.renderOrder = 1;
    if (isMobile()) {
      this.smoke.position.x = -1.6;
      this.smoke.position.y = -9.7;
      this.smoke.position.z = 0.905;
    } 

    this.scene.add(this.smoke);
    if (this.debug.active) {
      this.debugFolder
        .add(this.smoke.position, "x")
        .name("smokeX")
        .min(-5)
        .max(5)
        .step(0.001);
      this.debugFolder
        .add(this.smoke.position, "y")
        .name("smokeY")
        .min(-10)
        .max(5)
        .step(0.001);
      this.debugFolder
        .add(this.smoke.position, "z")
        .name("smokeZ")
        .min(-10)
        .max(10)
        .step(0.001);
    }
  }

  update() {
    this.smokeMaterial.uniforms.uTime.value = this.time.elapsedTime;
  }
}
