import Experience from "./Experience/Experience.js";
import emailjs from "@emailjs/browser";
import { isMobile } from "./Experience/Utils/Mobile.js";

const experience = new Experience(document.querySelector("canvas.webgl"));

document.addEventListener("DOMContentLoaded", () => {
  const mobileContainer = document.querySelector(".mobile-container");

  if (isMobile()) {
    mobileContainer.style.display = "flex";
  }

  document
    .getElementById("mobile-button")
    .addEventListener("click", function () {
      mobileContainer.style.display = "none";
    });

    const audio = document.getElementById("background-audio");
    const playPauseButton = document.getElementById("play-pause-button");
    audio.volume = 0.1;
    audio.muted = false; 

    const playIcon = "fa-solid fa-volume-high fa-2xl";
    const pauseIcon = "fa-solid fa-volume-xmark fa-2xl";

    playPauseButton.addEventListener("click", () => {
        if (audio.paused) {
            audio.play();
            playPauseButton.innerHTML = `<i class="${playIcon}"></i>`;
        } else {
            audio.pause();
            playPauseButton.innerHTML = `<i class="${pauseIcon}"></i>`;
        }
    });

  const closeButtons = document.querySelectorAll(".close-button");

  closeButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const globalDiv = button.closest(".global-div");
      if (globalDiv) {
        globalDiv.style.opacity = "0"; // Start fading out
        setTimeout(() => {
          globalDiv.style.display = "none"; // After the transition, set display to none
        }, 500); // Wait for the transition to complete (0.5s, same as in CSS)
      }
    });
  });
  emailjs.init("KHrAx8Y1-D9XN07ly");

  const form = document.getElementById("contact-form");
  const submitButton = document.getElementById("submit-button");
  // Get the form element
  if (form) {
    form.addEventListener("submit", function (event) {
      event.preventDefault();
      // these IDs from the previous steps
      emailjs.sendForm("contact_service", "contact_form", this).then(
        () => {
          // Display success message
          document.getElementById("form-message").innerHTML =
            "<p style='color:green;'>Message sent successfully!</p>";
          form.reset(); // Reset the form after successful submission
        },
        (error) => {
          // Display failure message
          document.getElementById("form-message").innerHTML =
            "<p style='color:red;'>Failed to send the message. Please try again later.</p>";
                  }
      );
    });
  }

  const artBtn = document.getElementById("art-projects-btn");
  const devBtn = document.getElementById("dev-projects-btn");
  const artProjects = document.getElementById("art-projects");
  const devProjects = document.getElementById("dev-projects");

  // Art Projects Button Click
  artBtn.addEventListener("click", function () {
    artProjects.classList.remove("hidden");
    devProjects.classList.add("hidden");
    artBtn.classList.add("active");
    devBtn.classList.remove("active");
  });

  // Dev Projects Button Click
  devBtn.addEventListener("click", function () {
    devProjects.classList.remove("hidden");
    artProjects.classList.add("hidden");
    devBtn.classList.add("active");
    artBtn.classList.remove("active");
  });
});
