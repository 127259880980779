import EventEmitter from "./EventEmitter.js";
import { isMobile } from "./Mobile.js";

export default class Sizes extends EventEmitter {
  constructor() {
    super();

    // Setup
    this.canvas = document.querySelector("canvas");
    if (isMobile()) {
      this.width = this.canvas.clientWidth;
      this.height = this.canvas.clientHeight;
    } else {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    }

    this.pixelRatio = Math.min(window.devicePixelRatio, 2);
    this.scrollY = window.scrollY;
    this.scrollValue = this.scrollY / this.height;
    // Resize event
    window.addEventListener("resize", () => {
      if (isMobile()) {
        this.width = this.canvas.clientWidth;
        this.height = this.canvas.clientHeight;
      } else {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      }
      this.pixelRatio = Math.min(window.devicePixelRatio, 2);
      this.trigger("resize");
    });

    window.addEventListener("scroll", () => {
      this.scrollY = window.scrollY;
      this.scrollValue = this.scrollY / this.height;
      this.trigger("scroll");
    });
  }
}
