import * as THREE from 'three'

import Debug from './Utils/Debug.js'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'
import Resources from './Utils/Resources.js'

import sources from './sources.js'
import mobilesources from './mobilesources.js'
import ModelGroup from './World/ModelGroup.js'
import RayCaster from './RayCaster.js'
import Overlay from './Overlay.js'
import PostProcessing  from './PostProcessing.js'
import { isMobile } from './Utils/Mobile.js'
import MobileWorld from './MobileWorld/MobileWorld.js'

// import Stats from 'stats.js'
let instance = null

export default class Experience
{
    constructor(_canvas)
    {
        // Singleton
        if(instance)
        {
            return instance
        }
        instance = this
        
        // Global access
        window.experience = this

        // Options
        this.canvas = _canvas

        // Setup
        // this.stats = new Stats();
        // this.stats.showPanel(0);
        // document.body.appendChild(this.stats.dom);


        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.group = new ModelGroup();
        this.resources = new Resources(sources, mobilesources)
        this.camera = new Camera()
        this.renderer = new Renderer()       
        this.raycaster = new RayCaster();
        this.overlay = new Overlay();
        //this.postProcessing = new PostProcessing();
        
        function debounce(func, wait = 10) {
            let timeout;
            return function() {
                const context = this, args = arguments;
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(context, args), wait);
            };
        }

        if(isMobile()) {
            this.world = new MobileWorld();
        } else {
            this.world = new World()
            this.postProcessing = new PostProcessing();
        }

        // Resize event
        this.sizes.on('resize', () =>
        {
            this.resize()
        })
        
        this.sizes.on('scroll', () =>
        {
            this.scroll()
        })

        // Time tick event
        this.time.on('tick', () =>
        {
            this.update()
        })
    }

    resize()
    {
        this.camera.resize()
        this.renderer.resize()

        if(!isMobile()) {
            this.postProcessing.resize()
        }
    }
    scroll() {
        this.camera.scroll()
    }
    update()
    {
        this.camera.update()
        this.world.update()
        this.renderer.update()
        this.raycaster.update()
        // this.stats.end();

        if(!isMobile()) {
            this.postProcessing.update()
        }
    }

    destroy()
    {
        this.sizes.off('resize')
        this.sizes.off('scroll')
        this.time.off('tick')

        // Traverse the whole scene
        this.scene.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()

        if(this.debug.active)
            this.debug.ui.destroy()
    }
}