import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { isMobile } from './Utils/Mobile.js'

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.debug = this.experience.debug;
    
        // Debug
        if (this.debug.active) {
          this.debugFolder = this.debug.ui.addFolder("Camera");
        }
        this.setInstance()
        //this.setControls()
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.01, 100)
        this.instance.position.z = 9;

        if(isMobile()) {
            this.instance.position.z = 20;
            this.instance.position.y = -0.05;
            this.instance.position.x = -0.23;
        }
        this.scene.add(this.instance)

        if (this.debug.active) {
            this.debugFolder
              .add(this.instance, "fov")
              .name("cameraFov")
              .min(0)
              .max(100)
              .step(0.5)
              .onChange(() => {
                this.instance.updateProjectionMatrix(); // Ensure projection matrix is updated when FOV changes
            });
            this.debugFolder
              .add(this.instance.position, "z")
              .name("cameraZ")
              .min(-10)
              .max(20)
              .step(0.01)
              .onChange(() => {
                this.instance.updateProjectionMatrix(); // Ensure projection matrix is updated when FOV changes
            });
            this.debugFolder
              .add(this.instance.position, "y")
              .name("cameraY")
              .min(-10)
              .max(20)
              .step(0.01)
              .onChange(() => {
                this.instance.updateProjectionMatrix(); // Ensure projection matrix is updated when FOV changes
            });
            this.debugFolder
              .add(this.instance.position, "x")
              .name("cameraX")
              .min(-10)
              .max(20)
              .step(0.01)
              .onChange(() => {
                this.instance.updateProjectionMatrix(); // Ensure projection matrix is updated when FOV changes
            });
        }
    }

    setControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }
    scroll() 
    {
        if(isMobile()) {
            this.instance.position.y = -this.sizes.scrollY / this.sizes.height * 6;
        } else {
            this.instance.position.y = -this.sizes.scrollY / this.sizes.height * 3.5;
        }
        
    }
    update()
    {
        //this.controls.update()
        //this.instance.position.y = this.sizes.scrollY;
    }
}