import * as THREE from "three";
import Experience from "../Experience.js";

export default class MobileCat {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.group = this.experience.group;
    this.sizes = this.experience.sizes;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("cat");
    }

    // Resource
    this.resource = this.resources.items.catModel;
    this.isPeach = true;
    this.socksColor = this.resources.items.socksBaseColor;
    this.socksColor.flipY = false;
    this.socksColor.colorSpace = THREE.SRGBColorSpace;
    this.orm = this.resources.items.peachORM;
    this.socksMaterial = new THREE.MeshStandardMaterial({
      map: this.socksColor,
      aoMap: this.orm,
    });

    this.peachColor = this.resources.items.peachBaseColor;
    this.peachColor.flipY = false;
    this.peachColor.colorSpace = THREE.SRGBColorSpace;
    this.peachMaterial = new THREE.MeshStandardMaterial({
      map: this.peachColor,
      aoMap: this.orm,
    });

    this.setModel();
    this.setAnimation();
  }

  checkModelLoaded() {
    if (
      this.experience.world &&
      this.experience.world.room &&
      this.experience.world.room.screenMaterial &&
      this.experience.world.environment.rectLight
    ) {
      this.monitor = this.experience.world.room.screenMaterial;
      this.monitorLight = this.experience.world.environment.rectLight
          } else {
            setTimeout(() => this.checkModelLoaded(), 100);
    }
  }

  setModel() {
    this.checkModelLoaded();
    this.model = this.resource.scene;

    this.group.addModel(this.model);

    this.model.traverse((child) => {
      if (child.isSkinnedMesh) {
        child.castShadow = true;
        child.receiveShadow = false;
        child.frustumCulled = false;
      }
    });
  }
  updateTexture() {
        if (this.isPeach) {
      this.model.traverse((child) => {
        if (child.isSkinnedMesh) {
          child.material = this.socksMaterial;
        }
      });
    } else {
      this.model.traverse((child) => {
        if (child.isSkinnedMesh) {
          child.material = this.peachMaterial;
        }
      });
    }
    this.isPeach = !this.isPeach;
  }
  setAnimation() {
    this.animation = {};

    // Mixer
    this.animation.mixer = new THREE.AnimationMixer(this.model);
    this.animation.mixer.timeScale = 1;

    // Actions
    this.animation.actions = {};
    this.animation.actions.jumpDown1 = this.animation.mixer.clipAction(
      this.resource.animations[3]
    );
    this.animation.actions.jumpDown2 = this.animation.mixer.clipAction(
      this.resource.animations[1]
    );
    this.animation.actions.deskIdle = this.animation.mixer.clipAction(
      this.resource.animations[2]
    );
    this.animation.actions.shelfIdle = this.animation.mixer.clipAction(
      this.resource.animations[4]
    );
    this.animation.actions.floorIdle = this.animation.mixer.clipAction(
      this.resource.animations[0]
    );
        //this.animation.actions.jumpDown1.play();
    this.animation.actions.jumpDown1.setLoop(THREE.LoopOnce);
    this.animation.actions.jumpDown1.clampWhenFinished = true;

    this.animation.actions.jumpDown2.setLoop(THREE.LoopOnce);
    this.animation.actions.jumpDown2.clampWhenFinished = true;

    this.animation.actions.deskIdle.clampWhenFinished = true;
    this.animation.actions.shelfIdle.clampWhenFinished = true;
    this.animation.actions.floorIdle.clampWhenFinished = true;

    this.animation.actions.shelfIdle.play();

    this.animation.mixer.addEventListener("finished", (event) => {
      if (event.action._clip.name === "JumpToDesk") {
                this.monitor.color = new THREE.Color(0xffffff);
        this.monitor.emissiveIntensity = 0.2;
        this.monitorLight.intensity = 1;
        this.animation.actions.deskIdle.crossFadeFrom(
          this.animation.actions.jumpDown1,
          0.1,
          true
        );
        this.animation.actions.deskIdle.play();
      }
      if (event.action._clip.name === "JumpToFloor") {
                this.animation.actions.floorIdle.crossFadeFrom(
          this.animation.actions.jumpDown2,
          0.1,
          true
        );
        this.animation.actions.floorIdle.play();
      }
    });
  }

  update() {
    //
    this.animation.mixer.update(this.time.delta * 0.001);
    if (this.sizes.scrollValue >= 0.6) {
      this.animation.actions.jumpDown1.play();
      this.animation.actions.shelfIdle.stop();
      this.animation.actions.shelfIdle.enabled = false;
      //this.animation.mixer.timeScale = 1;
    }

    if (
      this.sizes.scrollValue >= 1.5 &&
      !this.animation.actions.jumpDown2.isRunning() &&
      this.animation.actions.deskIdle.isRunning()
    ) {
      this.animation.actions.jumpDown2.reset().play();
      this.animation.actions.deskIdle.stop();
      this.animation.actions.deskIdle.enabled = false;
    }
  }
}



/*****Anim frames + names
 * -80 - 0 - Idle1
 * 0 - 65 - JumpToDesk
 * 66 - 130 Idle2
 * 131- 325 - JumpToFloor  
 * 325 - 400 - Idle3
 * 
 * Mobile
  * -80 - 0 - Idle1
 * 0 - 65 - JumpToDesk
 * 66 - 130 Idle2
 * 131- 302 - JumpToFloor  
 * 303 - 380 - Idle3 
 *  */ 
