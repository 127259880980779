import Experience from '../Experience.js'
import Environment from '../World/Environment.js'
import MobileEnvironment from './MobileEnvironment.js'
import MobileCat from './MobileCat.js'
import CoffeeSmoke from '../World/CoffeeSmoke.js'
import MobileRoom from './MobileRoom.js'


export default class MobileWorld
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () =>
        {
            this.environment = new MobileEnvironment()
            this.room = new MobileRoom();
            this.cat = new MobileCat();
            this.coffeesmoke = new CoffeeSmoke();
        })
    }

    update()
    {
        if(this.cat)
            this.cat.update()
        if(this.room)
            this.room.update()
        if(this.coffeesmoke)
            this.coffeesmoke.update()
    }
}