import * as THREE from 'three'
import Experience from '../Experience.js'
import { isMobile } from '../Utils/Mobile.js'

export default class ModelGroup
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        
        this.setInstance()

    }

    setInstance()
    {
        this.instance = new THREE.Group();
        this.instance.scale.setScalar(95);
        this.instance.position.set(0, -8, 0)
        if(isMobile()) {
            this.instance.position.y = -8.5;
        }
        this.instance.rotation.y = -Math.PI / 2;
        this.scene.add(this.instance)
    }

    addModel(model) 
    {
        this.instance.add(model)
    }

}