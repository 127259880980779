import * as THREE from "three";
import Experience from "../Experience.js";
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib';

export default class Environment {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("environment");
      this.debugFolder.close();
    }

    this.ambientLight = new THREE.AmbientLight(0xfff5e1, 0.1);
    this.scene.add(this.ambientLight);

    //this.setPointLight();
    this.setLampLight();
    this.setPCLight()
    this.setNightLight();
    this.setEnvironmentMap();
  }

  setPointLight() {
    this.pointLight = new THREE.PointLight(0xe8c274, 10);
    this.pointLight.castShadow = true;
    this.pointLight.shadow.camera.near = 0.1;
    this.pointLight.shadow.camera.far = 15;
    this.pointLight.shadow.mapSize.set(1024, 1024);
    this.pointLight.shadow.normalBias = 0.05;
    this.pointLight.shadow.radius = 10;
    this.pointLight.position.set(1, 5, 2);
    this.scene.add(this.pointLight);

    // Debug
    if (this.debug.active) {
      this.debugFolder
        .add(this.pointLight, "intensity")
        .name("pointLightIntensity")
        .min(0)
        .max(100)
        .step(0.5);

      this.debugFolder
        .add(this.pointLight.position, "x")
        .name("pointLightX")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.pointLight.position, "y")
        .name("pointLightY")
        .min(-10)
        .max(5)
        .step(0.1);

      this.debugFolder
        .add(this.pointLight.position, "z")
        .name("pointLightZ")
        .min(-5)
        .max(5)
        .step(0.001);
    }
  }
  setLampLight() {
    this.lampLight = new THREE.PointLight(0xe8c274, 1.5);
    this.lampLight.position.set(-1.5, 0.6, -0.7);
    this.lampLight.castShadow = true;
    this.lampLight.shadow.camera.near = 0.1;
    this.lampLight.shadow.camera.far = 15;
    this.lampLight.shadow.mapSize.set(1024, 1024);
    this.lampLight.shadow.normalBias = 0.05;
    this.lampLight.shadow.radius = 10;
    this.scene.add(this.lampLight);

    if (this.debug.active) {
      this.debugFolder
        .add(this.lampLight, "intensity")
        .name("lampLightIntensity")
        .min(0)
        .max(100)
        .step(0.5);

      this.debugFolder
        .add(this.lampLight.position, "x")
        .name("lampLightX")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.lampLight.position, "y")
        .name("lampLightY")
        .min(-10)
        .max(5)
        .step(0.1);

      this.debugFolder
        .add(this.lampLight.position, "z")
        .name("lampLightZ")
        .min(-5)
        .max(5)
        .step(0.001);
    }
  }

  setPCLight() {
    RectAreaLightUniformsLib.init();
    this.rectLight = new THREE.RectAreaLight(
      0xffffff,
      0,
      2.75,
      1.75
    );
    this.rectLight.position.set(2.38, -3.52, -0.82);
    //this.rectLight.lookAt(0, 0, 0);
    this.rectLight.rotation.set(2.95, -0.02, 0)
    this.scene.add(this.rectLight);

    // this.rectLightHelper = new RectAreaLightHelper(this.rectLight);
    // this.rectLight.add(this.rectLightHelper);

    if (this.debug.active) {
        this.debugFolder
          .add(this.rectLight, "intensity")
          .name("rectLightIntensity")
          .min(0)
          .max(100)
          .step(0.5);

        this.debugFolder
          .add(this.rectLight.position, "x")
          .name("x")
          .min(-10)
          .max(10)
          .step(0.01);
        this.debugFolder
          .add(this.rectLight.position, "y")
          .name("y")
          .min(-10)
          .max(10)
          .step(0.01);
        this.debugFolder
          .add(this.rectLight.position, "z")
          .name("z")
          .min(-10)
          .max(10)
          .step(0.01);
        this.debugFolder
          .add(this.rectLight.rotation, "x")
          .name("rotateX")
          .min(-6.28)
          .max(6.28)
          .step(0.001);
        this.debugFolder
          .add(this.rectLight.rotation, "y")
          .name("rotateY")
          .min(-6.28)
          .max(6.28)
          .step(0.001);
        this.debugFolder
          .add(this.rectLight.rotation, "z")
          .name("rotateZ")
          .min(-6.28)
          .max(6.28)
          .step(0.001);
        
    }
  }
  setNightLight() {
    this.nightLight = new THREE.PointLight(0xffd1a6, 1.5);
    this.nightLight.castShadow = true;
    this.nightLight.shadow.camera.near = 0.1;
    this.nightLight.shadow.camera.far = 15;
    this.nightLight.shadow.mapSize.set(1024, 1024);
    this.nightLight.shadow.normalBias = 0.05;
    this.nightLight.shadow.radius = 10;
    this.nightLight.position.set(2.542, -6.431, -1.637);
    this.scene.add(this.nightLight);

    // Debug
    if (this.debug.active) {
      this.debugFolder
        .add(this.nightLight, "intensity")
        .name("nightLightIntensity")
        .min(0)
        .max(100)
        .step(0.5);

      this.debugFolder
        .add(this.nightLight.position, "x")
        .name("nightLightX")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.nightLight.position, "y")
        .name("nightLightY")
        .min(-10)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.nightLight.position, "z")
        .name("nightLightZ")
        .min(-5)
        .max(5)
        .step(0.001);
    }
  }

  setEnvironmentMap() {
    this.environmentMap = {};
    this.environmentMap.intensity = 0.6;
    this.environmentMap.texture = this.resources.items.environmentMapTexture;
    this.environmentMap.texture.colorSpace = THREE.SRGBColorSpace;


    if (this.environmentMap.texture) {
      this.scene.environment = this.environmentMap.texture;

      this.environmentMap.updateMaterials = () => {
        this.scene.traverse((child) => {
          if (
            child instanceof THREE.Mesh &&
            child.material instanceof THREE.MeshStandardMaterial
          ) {
            child.material.envMap = this.environmentMap.texture;
            child.material.envMapIntensity = this.environmentMap.intensity;
            child.material.needsUpdate = true;
          }
        });
      };

      this.environmentMap.texture.onUpdate = () => {
        this.environmentMap.updateMaterials();
      };
   
      if (this.debug.active) {
        this.debugFolder
          .add(this.environmentMap, "intensity")
          .name("envMapIntensity")
          .min(0)
          .max(4)
          .step(0.001)
          .onChange(this.environmentMap.updateMaterials);
      }


      this.environmentMap.updateMaterials();
    } else {
      console.error("Environment map texture is not loaded yet.");
    }
  }
}
