import * as THREE from "three";
import Experience from "./Experience.js";
import { gsap } from "gsap";

export default class Overlay {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;

    this.setInstance();
  }

  setInstance() {
    this.overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
    this.overlayMaterial = new THREE.ShaderMaterial({
      // wireframe: true,
      transparent: true,
      uniforms: {
        uAlpha: { value: 1 },
      },
      vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
      fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `,
    });
    this.overlay = new THREE.Mesh(this.overlayGeometry, this.overlayMaterial);
    this.overlay.renderOrder = 999;
    this.scene.add(this.overlay);
  }

  animateMaterial() 
  {
    gsap.to(this.overlayMaterial.uniforms.uAlpha, {
        duration: 3,
        value: 0,
      });
  }

}
