import Experience from '../Experience.js'
import Environment from './Environment.js'
import Cat from './Cat.js'
import Room from './Room.js'
import CoffeeSmoke from './CoffeeSmoke.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () =>
        {
            this.environment = new Environment()
            this.cat = new Cat();
            this.room = new Room();
            this.coffeesmoke = new CoffeeSmoke();
        })
    }

    update()
    {
        if(this.cat)
            this.cat.update()
        if(this.room)
            this.room.update()
        if(this.coffeesmoke)
            this.coffeesmoke.update()
    }
}