import * as THREE from 'three'
import Experience from './Experience.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'
import { WebGLRenderTarget, HalfFloatType } from 'three';

export default class PostProcessing
{
    constructor()
    {
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera.instance
        this.renderer = this.experience.renderer.instance
        this.debug = this.experience.debug;

        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("Post-Processing");
        }

        this.setInstance()
    }

    setInstance()
    {

        this.renderTarget = new THREE.WebGLRenderTarget(
            800,
            600,
            {
                samples: this.renderer.getPixelRatio() === 1 ? 2 : 0,
                type: HalfFloatType,  
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding,
            }
        )
        this.instance = new EffectComposer(this.renderer, this.renderTarget);
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio)

        this.renderPass = new RenderPass(this.scene, this.camera)
        this.gammaCorrectionPass = new ShaderPass(GammaCorrectionShader)
        this.unrealBloomPass = new UnrealBloomPass()
        this.unrealBloomPass.strength = 1
        this.unrealBloomPass.radius = 1.1
        this.unrealBloomPass.threshold = 0.1
        
        this.smaaPass = new SMAAPass()
        this.instance.addPass(this.renderPass);
       // this.instance.addPass(this.gammaCorrectionPass)
        this.instance.addPass(this.unrealBloomPass)
        //this.instance.addPass(this.smaaPass);


        if (this.debug.active) {
            this.debugFolder
              .add(this.unrealBloomPass, "enabled")
              .name("bloomEnabled")
            this.debugFolder
              .add(this.unrealBloomPass, "strength")
              .name("bloomStrength")
              .min(0)
              .max(2)
              .step(0.001);

            this.debugFolder
              .add(this.unrealBloomPass, "radius")
              .name("bloomRadius")
              .min(0)
              .max(2)
              .step(0.001);
            this.debugFolder
              .add(this.unrealBloomPass, "threshold")
              .name("bloomThreshold")
              .min(0)
              .max(1)
              .step(0.001);
            }
    }
    resize()
    {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio)
    }

    update()
    {
        this.instance.render()
    }
}