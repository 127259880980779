import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import Experience from "../Experience.js";
import EventEmitter from "./EventEmitter.js";
import { isMobile } from "./Mobile.js";

export default class Resources extends EventEmitter {
  constructor(sources, mobileSources) {
    super();
    this.experience = new Experience();
    const pollInterval = setInterval(() => {
      this.overlay = this.experience.overlay;
      if (this.overlay) {
        clearInterval(pollInterval);
      } else {
              }
    }, 100);
    this.sources = isMobile() ? mobileSources : sources;
    this.items = {};
    this.toLoad = this.sources.length;
    this.loaded = 0;
    
    this.setLoaders();
    this.startLoading();
  }

  setLoaders() {
    this.loaders = {};
    this.loadingPercentageElement = document.querySelector(".loading-percentage");
    this.loadingGifElement = document.querySelector(".loading-gif");

    this.loadingManager = new THREE.LoadingManager(
      // Loaded
      () => {
        // Wait a little
        window.setTimeout(() => {
          // Animate overlay
          this.overlay.animateMaterial();
          this.loadingPercentageElement.remove();
          this.loadingGifElement.remove();
        }, 2000);
      },

      (itemUrl, itemsLoaded, itemsTotal) => {
        const progressRatio = itemsLoaded / itemsTotal;
        const percentage = Math.floor(progressRatio * 100);
        this.loadingPercentageElement.textContent = `${percentage}%`;
      }
    );
    
    this.loaders.gltfLoader = new GLTFLoader(this.loadingManager);
    this.loaders.textureLoader = new THREE.TextureLoader(this.loadingManager);
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(
      this.loadingManager
    );
  }

  startLoading() {
    // Load each source
    for (const source of this.sources) {
      if (source.type === "gltfModel") {
        this.loaders.gltfLoader.load(source.path, (file) => {
          // file.scene.traverse((child) => {
          //   if (child.isMesh) {
          //               //     child.material.normalMap = null;
          //     child.material.needsUpdate = true;
          //   }
          // });
          this.sourceLoaded(source, file);
        });
      } else if (source.type === "texture") {
        this.loaders.textureLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      } else if (source.type === "cubeTexture") {
        this.loaders.cubeTextureLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      }
    }
  }

  sourceLoaded(source, file) {
    this.items[source.name] = file;

    this.loaded++;

    if (this.loaded === this.toLoad) {
      this.trigger("ready");
    }
  }
}
